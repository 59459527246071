let img_square, img_circle;
let s1, s2;
let globalSize;

function preload() {
    img_square = loadImage('/wp-content/themes/attrivali/images/sketch_square_001.png');
    img_circle = loadImage('/wp-content/themes/attrivali/images/sketch_circle_001.png');
}

function setup() {
    let canvas = createCanvas(windowWidth, windowHeight);
    canvas.parent('sketch-holder');
    globalSize = 450;
    //frameRate(2);
    
    s1 = new Shape(windowWidth / 2 - globalSize / 2, windowHeight / 2, globalSize, img_square, "square");
    s2 = new Shape(windowWidth / 2 + globalSize / 2, windowHeight / 2, globalSize, img_circle, "circle");

    noStroke();
    windowResized();
    //noSmooth();
}

function draw() {
    background(35, 31, 32);

    s1.update();
    s1.display();

    s2.update();
    s2.display();

    //console.log(frameRate());
}
function windowResized() {
    resizeCanvas(windowWidth, windowHeight);

    globalSize = 450;
    if(windowWidth < 1600){
        globalSize = 400;
    }
    if (windowWidth < 1200){
        globalSize = 350;
    }
    if (windowWidth < 1024) {
        globalSize = 300;
    }
    if (windowWidth < 768) {
        globalSize = 200;
    }

    s1.setPosition(windowWidth / 2 - globalSize/2, windowHeight / 2);
    s2.setPosition(windowWidth / 2 + globalSize/2, windowHeight / 2);

    s1.setSize(globalSize);
    s2.setSize(globalSize);
}

// Shape class
class Shape {
    constructor(_x, _y, _size, _img, type) {
        this.setPosition(_x, _y);
        this.imgOG = _img;
        this.img = _img;
        this.setSize(_size);
        this.type = type;
        this.rot = 0;
        this.opacity = 0;
    }

    setPosition(_x, _y) {
        this.pos = createVector(_x, _y);
    }
    setSize(_size) {
        this.size = _size;
        if(this.pg){
            this.pg.remove();
        }
        this.pg = createGraphics(this.size, this.size);
        this.img = this.imgOG.get();
    }

    update() {
        let mousePos = createVector(mouseX, mouseY);
        let dir = p5.Vector.sub(mousePos, this.pos);
        let rotTgt = dir.heading() + PI;

        let rotDif = (rotTgt - this.rot + PI) % TWO_PI - PI;
        if (rotDif < -PI) {
            rotDif += TWO_PI;
        }

        this.rot += rotDif / 10;

        let sc = 1;
        if (this.type == "square") {
            let t = abs(tan(this.rot));
            let b = this.size / (t + 1);
            let a = this.size - b;
            let c = sqrt(sq(a) + sq(b));
            sc = this.size / c;
        }

        this.img.resize(this.size, this.size);

        this.pg.background(35, 31, 32);
        this.pg.push();
        this.pg.translate(this.size / 2, this.size / 2);
        this.pg.rotate(this.rot);
        this.pg.scale(sc);
        this.pg.translate(-this.size / 2, -this.size / 2);
        this.pg.image(this.img, 0, 0);
        this.pg.pop();

        if(this.opacity < 255){
            this.opacity += 3;
        }
    }

    display() {
        push();
        translate(this.pos.x, this.pos.y);
        translate(-this.size / 2, -this.size / 2);
        if (this.opacity < 255) {
            tint(255, this.opacity);
        }
        image(this.pg, 0, 0);
        pop();
    }
}